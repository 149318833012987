import '../shared.js';
import jquery from "jquery";

export default (window.$ = window.jQuery = jquery);

$(document).ready(function(){
    $('.partners').slick({
        rows: 4,
        responsive: [
            {
              breakpoint: 1924,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000
              }
            }
          ]
    });
  });